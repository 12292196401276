<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span>Use Case / Draft a Contract </span>
      </div>

      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >

          <span style="margin: 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="left-container">
          <section class="tabs">
            <h6 class="tabs-title">Draft a Contract</h6>
            <p class="tabs-description">Enter your contract details.</p>
          </section>
          <section class="upload-file">
            <UploadFiles @get-query="getQuery" not-send only-query />

            <div class="d-flex justify-content-end mt-3">
              <button
                :disabled="!isAllFieldValid"
                :class="{
                  'button-generate': isAllFieldValid,
                  'button-generate-muted': !isAllFieldValid,
                }"
                style="
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  cursor: pointer;
                "
                class="me-2"
              >
                <!-- @click="uploadFile" -->
                <span class="material-symbols-rounded icon">upload</span>
                Create a MS Word
              </button>
              <div
                :disabled="!isAllFieldValid"
                :class="{
                  'button-generate': isAllFieldValid,
                  'button-generate-muted': !isAllFieldValid,
                }"
                style="
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  cursor: pointer;
                "
                @click="uploadFile"
              >
                <span class="material-symbols-rounded icon">send</span>
                Draft Contract
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
    ></sidebar>
  </div>
</template>

<script>
import countryImages from "./../../../assets/country/index";
import sidebar from "@/Pages/Mode/sidebar.vue";
import usecase from "@/store/usecase.js";
import UploadFiles from "@/components/input/UploadFiles.vue";
export default {
  components: { UploadFiles, sidebar },
  data() {
    return {
      loading: true,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      askQuestion: "",
      countryImages,
      files: [[], [], []],
      selectedCountry: "",
    };
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    getQuery(query) {
      this.askQuestion = query;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },

    async uploadFile() {
      if (!this.isAllFieldValid) {
        return;
      }
      try {
        const formData = new FormData();

        formData.append("language", "en");
        formData.append("location_id", 1);
        formData.append("type", "build_contract");
        formData.append("question", this.askQuestion);
        const response = await usecase.CreateUsecase(formData);
        if (response && response.data) {
          this.$toast.success("Successfully uploaded files.");
          this.$router.push({
            name: "DraftContractResult",
            params: { id: response.data.data.id },
          });
        } else {
          this.$toast.error("Upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        this.$toast.error(
          "An error occurred while uploading files. Please try again."
        );
      }
    },
  },
  computed: {
    isAllFieldValid() {
      if (this.askQuestion != null) {
        return this.askQuestion.trim() != "" ? true : false;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.footer-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
}
.button-generate {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  background-color: var(--Primary-Blue, #0e4485);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.button-generate-muted {
  border-radius: 4px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  background: rgba(14, 68, 133, 0.1);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
}

.juri {
  position: relative;
  display: inline-block;
}

.country-option {
  margin-top: 10%;
  left: 2%;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.country-option ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.countryOption {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.countryOption:hover {
  background-color: #f1f1f1;
}

.country-flag {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.icon-small {
  margin-right: 8px;
  font-size: 24px;
}
</style>
<style scoped>
.page-content {
  padding: 0;
}

.find-pre {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
}
.find-pre:hover {
  background: var(--primary);
  color: #fff;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 5px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
</style>

<style scoped>
.left-container {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(170, 171, 175, 0.6);
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .left-container {
    padding: 0 20px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.tabs-title {
  color: #0e4485 !important;
}
.tabs-description {
  color: #86888d !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.query-section {
  margin-top: 32px;
  font: 16px/28px Poppins, sans-serif;
}
.case-file {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font-weight: 500;
  justify-content: center;
}

.case-file-title {
  margin-bottom: 4px;
}

.content {
  color: #d1d2d5;
  font-weight: 400;
}
.searchbar {
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

.entry {
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.legal-input {
  background: none;
  width: 100%;
}

.line {
  border: 1px solid #000;
  height: 28px;
  align-self: start;
}

.char-count {
  align-self: end;
  margin-top: 40px;
}
.upload-file {
  margin-top: 32px;
  flex-direction: column;
  justify-content: flex-start;
}

.upload-section {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font: 500 16px/28px Poppins, sans-serif;
}
.upload-title {
  margin-bottom: 8px;
}

.upload-description {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
}

.upload {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.upload-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d1d2d5;
  border-radius: 8px;
  background-color: #fafbfc;
  min-width: 240px;
  padding: 27px 80px;
}
.upload-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-image {
  width: 32px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}
.upload-caption {
  color: #0e4485;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.upload-choose-file {
  color: #0e4485;
}

.upload-file-format {
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.upload-instruction {
  background-color: #fafbfc;
  border-radius: 8px;
  min-width: 240px;
  padding: 58px 70px;
  box-shadow: 0px 0px 4px rgba(242, 243, 243);
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.storage-info {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
  margin-top: 8px;
}

.folder-creation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.toggle {
  align-items: center;
  display: flex;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background-color: #d1d2d5;
  position: relative;
}
.toggle-checkbox {
  opacity: 0;
  position: absolute;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: #0e4485;
}

.toggle-checkbox:checked + .toggle-switch::before {
  transform: translateX(20px);
}

.toggle-switch::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  transition: transform 0.3s;
  box-shadow: 0px 2px 4px rgba(39, 39, 39, 0.1);
}

.toggle-label {
  font: 400 14px/24px Poppins, sans-serif;
  color: #383a3e;
}
.generate-button {
  align-self: end;
  border-radius: 4px;
  background-color: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
  padding: 4px 16px;
  margin-top: 32px;
  font: 500 16px Poppins, sans-serif;
}
</style>
<style scoped>
.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5 {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.right-container {
  height: 182px;
  display: flex;
  padding: 0px 53px 0px 99px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  box-shadow: 0px 0px 4px 0px #f2f3f3;
  p {
    color: var(--Neutral-Dark-Grey, #86888d) !important;

    /* Small Paragraph */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.toggle {
  display: flex;
  width: 40px;
}
.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}
.swift:hover {
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  border-radius: 4px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.upload {
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover {
  background: var(--primary);
}

.country-option {
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.countryOption {
  cursor: pointer;
  margin-bottom: 10px;
  span {
    color: #86888d;
    font-size: 14px;
  }
}
.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}
.toggledp {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
}
.country-button {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.juri {
  width: 160px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
